//$primary: #132742;
//$secondary: #3871C2;
//$tertiary: #FF4585;

$primary: #3871C2;
$secondary: #FF4585;
$tertiary: #132742;

$success: #63b160;
$danger: #fa6557;
$warning: #efa755;

/* these are non- bootstrap variables. */
$content-bg: #FFF;
$card-bg: $content-bg;
$list-bg: #FFF;
$table-bg: #FFF;
$navbar-bg: $tertiary;

$sidebar-bg: #fff;
$sidebar-highlight: $tertiary;
$sidebar-selected: $secondary;
$sidebar-selected-bg: #f5f5f5;
$sidebar-menu-btn: #000;
/* /these are non- bootstrap variables. */

$body-bg: lighten(#262626, 81%);
$body-color: #232832;
$input-placeholder-color: #cbcbcb;
$headings-color: #1f232b;

$form-label-color: #586071;
$form-label-font-weight: 500;
$form-label-font-size: 14px;
$form-label-margin-bottom: 0.4rem;

$input-label-color: #262626;
$input-label-weight: 300;
$input-bg: #fafafa;
$input-border-color: #e3e3e3;
$input-border-width: 1px;
$input-focus-bg: #FFF;

$dropdown-link-hover-bg: $primary;
$dropdown-link-active-bg: darken($primary, 10%);
$dropdown-link-hover-color: #fff;

$border-radius:    3px;
$border-radius-lg: 3px;
$border-radius-sm: 3px;

$input-padding-x: 0.5rem;
$input-padding-y: 0.3rem;

$input-btn-padding-x: 0.7rem;
$input-btn-padding-y: 0.3rem;

$input-font-size: 0.95rem;
$input-btn-font-size: 0.95rem;

$font-family-base: 'Roboto';
$font-family-sans-serif: 'Roboto';
$headings-font-family: 'Asap Condensed';
$btn-font-family: 'Roboto';

$btn-font-size-sm: 0.75rem;
$btn-padding-x-sm: 10px;
$btn-padding-y-sm: 2px;

$enable-responsive-font-sizes: true;

$table-hover-bg: #ececec;

@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import "../../node_modules/bootstrap/scss/variables-dark";
@import '../../node_modules/bootstrap/scss/maps';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/utilities';
@import '../../node_modules/bootstrap/scss/root';
