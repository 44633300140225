@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,900;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Asap+Condensed:wght@600&display=swap');

@import '../node_modules/quill/dist/quill.core.css';
@import '../node_modules/quill/dist/quill.snow.css';

@import './scss/bootstrap_base';

@import '../node_modules/bootstrap/scss/bootstrap';
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/helpers";

@import '../node_modules/bootstrap/scss/bootstrap-reboot';
@import '../node_modules/bootstrap/scss/bootstrap-grid';

@import 'scss/components/toastr-bs5-alert';
@import '../node_modules/font-awesome/css/font-awesome.min.css';

@import 'scss/components/sidebar';
@import 'scss/components/ngselect';

html {
  overflow-y: scroll !important;
}

body {
  //overflow-y: scroll !important;
  padding-right: 0 !important;
  display: block;
  background-color: $navbar-bg;

  &.modal-open {
    overflow: unset;
    .modal {
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  }

  // hack fix for ios pwa statusbar colour
  &:before {
    content: '';
    position:fixed;
    width: 100vw;
    height: 100px;
    top: -100px;
    left: 0;
    background-color: $navbar-bg;
    z-index: 10000000000;
  }
  &:after {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $body-bg;
    z-index: -1;
  }
}

.content-container {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  border-radius: $border-radius;
  background-color: $content-bg;
  padding: 1em;
  margin-bottom: 3em;
  clear: both;
  @include media-breakpoint-down(sm) {
    border-radius: 0;

    margin-left: -8px;
    margin-right: -8px;
    padding: 0.5rem!important;
  }
  @include media-breakpoint-down(xs) {
    padding: 0.6em;
  }
}


.app-wrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: visible;
  position: relative;

  background-color: $body-bg;

  //min-height: 100vh;

  &.top-bar {
    min-height: calc(100vh - 60px);
    padding-top: 60px;

    &.offline {
      min-height: calc(100vh - 80px);
      padding-top: 80px;
      &::ng-deep {
        .requests-wrapper, .requests {
          height: calc(100vh - 80px);
        }
      }
    }

  }

  main {
    flex: 1;
    min-width: 0;
    position: relative;

    > .container-fluid {
      max-width: 1200px;
    }

    &.centered {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

}

@media print{
  .table{
    th,
    td,
    thead th,
    tbody + tbody {
      border: 0;
    }
  }
}

@import './scss/helpers/_root';
@import '../node_modules/@hutsix/ngxh6/src/styles.theme';
@import './scss/overrides';
//@import '~src/scss/darkmode';
@import './scss/print';

